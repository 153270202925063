.mg-active-datapoint {
    fill: black;
    font-size: 0.9rem;
    font-weight: 400;
    opacity: 0.8;
}

.mg-area1-color {
    fill: #0000ff;
}

.mg-area2-color {
    fill: #05b378;
}

.mg-area3-color {
    fill: #db4437;
}

.mg-area4-color {
    fill: #f8b128;
}

.mg-area5-color {
    fill: #5c5c5c;
}

.mg-barplot rect.mg-bar {
    shape-rendering: auto;
    fill: #b6b6fc;
}

.mg-barplot rect.mg-bar.active {
    fill: #9e9efc;
}

.mg-barplot .mg-bar-prediction {
    fill: #5b5b5b;
}

.mg-barplot .mg-bar-baseline {
    stroke: #5b5b5b;
    stroke-width: 2;
}

.mg-baselines line {
    opacity: 1;
    shape-rendering: auto;
    stroke: #b3b2b2;
    stroke-width: 1px;
}

.mg-baselines text {
    fill: black;
    font-size: 0.7rem;
    opacity: 0.5;
    stroke: none;
}

.mg-baselines-small text {
    font-size: 0.6rem;
}

.mg-chart-title .popover {
    font-size: 0.95rem;
}

.mg-chart-title .popover-content {
    cursor: auto;
    line-height: 17px;
}

.mg-chart-title .popover.top,
.mg-data-table .popover.top {
    margin-top: 0;
}

.mg-chart-title {
    cursor: default;
    font-size: 1.8rem;
    padding-top: 8px;
    text-align: center;
}

.mg-chart-title .fa {
    color: #ccc;
    font-size: 1.2rem;
    padding-left: 4px;
    vertical-align: top;
}

.mg-chart-title .fa.warning {
    font-weight: 300;
}

.mg-points circle {
    opacity: 0.85;
}

.mg-data-table {
    margin-top: 30px;
}

.mg-data-table thead tr th {
    border-bottom: 1px solid darkgray;
    cursor: default;
    font-size: 1.1rem;
    font-weight: normal;
    padding: 5px 5px 8px 5px;
    text-align: right;
}

.mg-data-table thead tr th .fa {
    color: #ccc;
    padding-left: 4px;
}

.mg-data-table thead tr th .popover {
    font-size: 1rem;
    font-weight: normal;
}

.mg-data-table .secondary-title {
    color: darkgray;
}

.mg-data-table tbody tr td {
    margin: 2px;
    padding: 5px;
    vertical-align: top;
}

.mg-data-table  tbody tr td.table-text {
    opacity: 0.8;
    padding-left: 30px;
}

.mg-y-axis line.mg-extended-y-ticks {
    opacity: 0.4;
}

.mg-x-axis line.mg-extended-x-ticks {
    opacity: 0.4;
}

.mg-histogram .axis path,
.mg-histogram .axis line {
    fill: none;
    opacity: 0.7;
    shape-rendering: auto;
    stroke: #ccc;
}

.mg-histogram .mg-bar rect {
    fill: #b6b6fc;
    shape-rendering: auto;
}

.mg-histogram .mg-bar rect.active {
    fill: #9e9efc;
}

.mg-least-squares-line {
    stroke: red;
    stroke-width: 1px;
}

.mg-lowess-line {
    fill: none;
    stroke: red;
}

.mg-line1-color {
    stroke: #4040e8;
}

.mg-hover-line1-color {
    fill: #4040e8;
}

.mg-line2-color {
    stroke: #05b378;
}

.mg-hover-line2-color {
    fill: #05b378;
}

.mg-line3-color {
    stroke: #db4437;
}

.mg-hover-line3-color {
    fill: #db4437;
}

.mg-line4-color {
    stroke: #f8b128;
}

.mg-hover-line4-color {
    fill: #f8b128;
}

.mg-line5-color {
    stroke: #5c5c5c;
}

.mg-hover-line5-color {
    fill: #5c5c5c;
}

.mg-line-legend text {
    font-size: 0.9rem;
    font-weight: 300;
    stroke: none;
}

.mg-line1-legend-color {
    color: #4040e8;
    fill: #4040e8;
}

.mg-line2-legend-color {
    color: #05b378;
    fill: #05b378;
}

.mg-line3-legend-color {
    color: #db4437;
    fill: #db4437;
}

.mg-line4-legend-color {
    color: #f8b128;
    fill: #f8b128;
}

.mg-line5-legend-color {
    color: #5c5c5c;
    fill: #5c5c5c;
}

.mg-main-area-solid svg .mg-main-area {
    fill: #ccccff;
    opacity: 1;
}

.mg-markers line {
    opacity: 0.9;
    shape-rendering: auto;
    stroke: #b3b2b2;
    stroke-width: 1px;
}

.mg-markers text {
    fill: black;
    font-size: 0.7rem;
    opacity: 0.5;
    stroke: none;
}

.mg-missing-text {
    opacity: 0.9;
}

.mg-missing-background {
    stroke: blue;
    fill: none;
    stroke-dasharray: 10,5;
    stroke-opacity: 0.05;
    stroke-width: 2;
}

.mg-missing .mg-main-line {
    opacity: 0.1;
}

.mg-missing .mg-main-area {
    opacity: 0.03;
}

path.mg-main-area {
    opacity: 0.2;
    stroke: none;
}

path.mg-confidence-band {
    fill: #ccc;
    opacity: 0.4;
    stroke: none;
}

path.mg-main-line {
    fill: none;
    opacity: 0.8;
    stroke-width: 1.1px;
}

.mg-points circle {
    fill-opacity: 0.4;
    stroke-opacity: 1;
}

circle.mg-points-mono {
    fill: #0000ff;
    stroke: #0000ff;
}

/* a selected point in a scatterplot */
.mg-points circle.selected {
    fill-opacity: 1;
    stroke-opacity: 1;
}

.mg-voronoi path {
    fill: none;
    pointer-events: all;
    stroke: none;
    stroke-opacity: 0.1;
}

.mg-x-rug-mono,
.mg-y-rug-mono {
    stroke: black;
}

.mg-x-axis line,
.mg-y-axis line {
    opacity: 1;
    shape-rendering: auto;
    stroke: #b3b2b2;
    stroke-width: 1px;
}

.mg-x-axis text,
.mg-y-axis text,
.mg-histogram .axis text {
    fill: black;
    font-size: 0.9rem;
    opacity: 0.5;
}

.mg-x-axis .label,
.mg-y-axis .label,
.mg-axis .label {
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 400;
}

.mg-x-axis-small text,
.mg-y-axis-small text,
.mg-active-datapoint-small {
    font-size: 0.6rem;
}

.mg-x-axis-small .label,
.mg-y-axis-small .label {
    font-size: 0.65rem;
}

.mg-year-marker text {
    fill: black;
    font-size: 0.7rem;
    opacity: 0.5;
}

.mg-year-marker line {
    opacity: 1;
    shape-rendering: auto;
    stroke: #b3b2b2;
    stroke-width: 1px;
}

.mg-year-marker-small text {
    font-size: 0.6rem;
}
